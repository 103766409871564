<template>
  <section class="mt-5">
    <div v-if="!loading">
      <Loading />
    </div>
    <div v-if="loading">
      <div class="flexBox iconText viewListingButton">
        <div class="width100 text-right">
          <a
            uk-sticky
            :href="`https://www.rightmove.co.uk/properties/${property.propertyId}`"
            target="blank"
            ><v-btn large> View Listing </v-btn></a
          >
        </div>
      </div>
      <v-container class="contain">
        <v-row>
          <v-col md="10">
            <h1>
              {{ property.propertyAddress }}
            </h1>
            <p class="text-capitalize">
              {{ property.houseSubtype }}
            </p>
          </v-col>
          <v-col md="2">
            <h1 class="text-right priceBox">
              ₤ {{ numberFormat(property.price) }}
              <v-icon @click="wishlistAct" v-if="!property.wishlist">
                mdi-heart-outline
              </v-icon>
              <v-icon
                @click="wishlistAct"
                class="wishlist"
                v-if="property.wishlist"
              >
                mdi-heart
              </v-icon>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <img src="@/assets/images/house.svg" alt="" />
              <div>
                <span>Property Type</span>
                <h2>{{ property.houseSubtype }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <img src="@/assets/images/bed.svg" alt="" />
              <div>
                <span>Bedroom</span>
                <h2>{{ property.bedrooms }}</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Listed On</span>
                <h2>{{ property.listedDate }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="2">
            <div class="flexBox iconText">
              <div>
                <span>Listing Status</span>
                <h2>
                  {{
                    !property.isListingLive
                      ? "Off From The Market"
                      : "Still Live"
                  }}
                </h2>
              </div>
            </div>
          </v-col>

          <v-col> </v-col>
        </v-row>

        <v-row>
          <v-col md="6" cols="12">
            <h3 class="mb-5">Key Features :</h3>
            <ul class="keyFeatures">
              <li
                v-for="(data, index) in property.propertykeyFeatures"
                :key="index + 'keyfeature'"
              >
                {{ data.featureDescription }}
              </li>
            </ul>
          </v-col>
          <v-col md="6" cols="12">
            <h3 class="mb-5">Property Photos :</h3>
            <slick ref="slick" :options="slickOptions">
              <div
                class="image"
                v-for="(data, index) in property.photos"
                :key="'as' + index"
              >
                <img :src="data.url" class="imageBox" alt="" />
              </div>
            </slick>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>Letting Details</h3>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Let available date</span>
                <h2>Ask Agent</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Deposite</span>
                <h2>₤ {{ numberFormat(property.letBond) }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Min. Tenancy</span>
                <h2>Ask Agent</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Let type</span>
                <h2>Long Term</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span> Furnish type</span>
                <h2>{{ property.letFurnishType }}</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>About the Agent</h3>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Logo</span>
                <img :src="property.branch.largeBranchLogo" alt="" />
              </div>
            </div>
          </v-col>

          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Name</span>
                <h2>
                  {{ property.branch.name }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Phone</span>
                <h2>
                  {{ property.telephoneNumber }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Refs</span>
                <h2>
                  {{ property.agentRef }}
                </h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Address</span>
                <h2>
                  {{ property.branch.address }}
                </h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>Financials</h3>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Asking Price</span>
                ₤ {{ numberFormat(property.monthlyRent) }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>Property Description</h3>
          </v-col>
          <v-col md="12">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <p v-html="property.propertyDescription"></p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>Letting Fees Message</h3>
          </v-col>
          <v-col md="12">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <p v-html="property.lettingFeesMessage"></p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/dashboard/Loading";
import PropertyUpdate from "@/components/dashboard/PropertyUpdate";
import { mapActions, mapGetters } from "vuex";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
export default {
  name: "SingleRentProperty",
  data: () => ({
    property: undefined,
    slickOptions: {
      slidesToShow: 1,
      dots: false,
      arrows: true,
      fade: false,
    },
    loading: false,
  }),
  components: {
    PropertyUpdate,
    Loading,
    Slick,
  },
  computed: {
    ...mapGetters(["allSingleProperty"]),
  },
  methods: {
    ...mapActions(["getSingleRentPropertyDetails", "postWishlist"]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    async wishlistAct() {
      this.property.wishlist = !this.property.wishlist;
      await this.postWishlist({
        propertyId: this.property.propertyId,
        propertyFor: "rent",
      });
    },
  },
  async created() {
    await this.getSingleRentPropertyDetails(this.$route.params.id);
    this.property = this.allSingleProperty.results;
    this.loading = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
h1 {
  font-size: 30px;
}
h2 {
  font-size: 18px;
  font-weight: 400;
}
.iconText {
  img {
    margin-right: 15px;
  }
  span {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
  }
}
.listSingle {
  list-style: none;
  margin-top: 10px;
  padding-left: 20px;
  li {
    line-height: 30px;
  }
}
.imageproperty {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
  }
  img {
    width: 300px;
  }
}
.keyFeatures {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: -5px;
  li {
    margin: 5px 20px;
    font-size: 18px;
    width: 40%;
  }
}
.table-border {
  margin-top: 15px;
  margin-left: 20px;
  border-collapse: collapse;
  width: 50%;
  th {
    padding: 10px;
  }
  td {
    padding: 10px;
  }
}
.editBox {
  display: flex;
  align-items: center;
  font-weight: 600;
  .v-text-field {
    margin-bottom: -20px;
    font-weight: 600;
  }
}
.width100 {
  width: 100% !important;
  .v-btn {
    width: 80%;
  }
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    display: flex;
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}

.images {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 4px;
  }
  img {
    width: 220px;
  }
}
.flexBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  strong {
    margin-top: 5px;
    display: flex;
    width: 30px;
  }
  .textBox {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.imageBox {
  background: #f4f4f4;
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
h3 {
  color: #09395a;
  font-size: 24px;
}
.boxborder {
  margin-top: 10px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 30px;
  background: $white;
  h3 {
    margin-bottom: 0;
  }
}
.fullwidth {
  width: 100%;
  .v-text-field {
    margin-top: 5px;
  }
}
.bgColor {
  margin-top: 30px;
  position: relative;
  div {
    position: relative;
    z-index: 9;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    background: #f4f4f4;
    left: -500px;
    right: -500px;
    height: 100%;
  }
}
.contain {
  width: 1200px;
  // left: -200px;
  // position: relative;
}
.fixedBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  text-align: center;
  img {
    width: 700px;
    margin: 100px;
  }
}
.uk-sticky {
  margin: 0;
  z-index: 999999;
  width: 200px;
  text-decoration: none;
}
.uk-active {
  // top: 85vh !important;
  right: 12%;
  .v-btn {
    width: 50%;
    padding: 10px !important;
    top: 10px;
  }
}
.floodRisk {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  h3 {
    font-size: 16px;
  }
}
.flexBox {
  span {
    font-weight: 600;
    color: #09395a;
  }
}
.priceBox {
  .v-icon {
    margin-left: 20px;
    font-size: 30px !important;
    &:hover {
      cursor: pointer;
    }
  }
  .wishlist {
    color: red;
  }
}
.viewListingButton {
  position: fixed;
  top: 25px;
  z-index: 999;
  height: 10px;
  width: 200px;
  right: 200px;
}
</style>